/* eslint-disable no-unused-vars */ // CI treats warnings as errors - build fails
import React, { useGlobal } from 'reactn';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';

import SvgIcon from '../SvgIcon';
import { leftArrow } from '../../styles/icons/svgPaths';
import Logo from '../../styles/images/rootpayment.png';
// import ReactTabs from './ReactTabs';
import TabsMUI from './TabsMUI';
// import TabsMUIwStyles from './TabsMUIwStyles';
import './loginPage.css';

export default function LoginPage() {
  const [windowWidth] = useGlobal('windowWidth');
  // complex options = [ login, code, merchant ]
  const [complex, setComplex] = React.useState('login');
  return (
    <div className="login-columns">
      <div className="login-content">
        <Link to="/" className="back-link">
          <SvgIcon
            icon={leftArrow.d}
            viewBox="0 0 22 22"
            size={22}
            color="#bcc978"
            className="back-icon"
          />
          Back Home
        </Link>
        <img src={Logo} className="tree-logo" alt="Root Payments Logo" />

        {/* Login Complex */}
        {/* <ReactTabs /> */}
        {/* <TabsMUIwStyles /> */}

        <TabsMUI complex={complex} setComplex={setComplex} />
      </div>
      {windowWidth > 920 && (
        <div className="login-splash">
          <div className="login-splash-header">Well Hello There</div>
          <p>Get Ready to Accept 7+ Crypto Currencies on Your Site</p>
        </div>
      )}
    </div>
  );
}
