/* eslint-disable no-unused-vars */ // CI treats warnings as errors - build fails
import React from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../inputs/TextInput';

// either popup or in place of form in LoginPage
// takes security code from email
export default function EnterCode(props) {
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState('');

  function handleSubmit(event, code) {
    // validate -> set error?
    // TODO request POST to API
    console.log('code', code);
  }

  return (
    <div>
      <form>
        {/* Disabled switch - message */}
        <h2>Please check your email</h2>
        <TextInput
          htmlId="securityCode"
          name="securityCode"
          label="securityCode"
          type="text"
          required
          onChange={setCode}
          placeholder="Security Code"
          value={code}
          // readOnly={true}
          error={error}
          // props="readOnly"
          // children
        />
        <button
          type="submit"
          className="login-button"
          onClick={() => props.setComplex('merchant')}
        >
          Enter
        </button>
      </form>
    </div>
  );
}

EnterCode.propTypes = {
  setComplex: PropTypes.func.isRequired,
};
