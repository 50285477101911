import React, { setGlobal } from 'reactn';
import { Route, Switch } from 'react-router';
// import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import HomePage from '../HomePage';
import LoginPage from '../loginPage/LoginPage';
import Dashboard from '../dashboard/Dashboard';
import './App.css';

function setMedia() {
  setGlobal({ windowWidth: window.innerWidth });
}

function App() {
  window.addEventListener('load', setMedia);
  window.addEventListener('resize', setMedia);
  return (
    <div>
      <ThemeProvider>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={LoginPage} />
          <Route path="/dashboard" component={Dashboard} />
        </Switch>
      </ThemeProvider>
    </div>
  );
}

export default App;
