import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';

setGlobal({
  // display
  windowWidth: 0,
  authenticated: true,
  // profiles
  name: '',
  merchantId: null,
  // data
  currencyDataFetched: false,
  currencies: {},
  txnDataFetched: false,
  txns: null,
  apisFetched: false,
  publicAPI: null,
  privateAPI: null,
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
