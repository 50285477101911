import React from 'reactn';
import { Link } from 'react-router-dom';

import './header.css';

export default function Header(props) {
  return (
    <div className="header">
      <a href="#logo">(Logo)</a>
      <div className="menu">
        <div className="menu-link">Pricing</div>
        <div className="menu-link">Company</div>
        <div className="menu-link">Support</div>
        <div className="button-signin menu-link">
          {/* Icon */}
          <Link to="/login">Sign In</Link>
        </div>
        <div className="button-signup menu-link">
          <Link to="/register">Sign Up</Link>
        </div>
      </div>
    </div>
  );
}
