/* eslint-disable no-unused-vars */ // CI treats warnings as errors - build fails
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import request from 'superagent';

import TextInput from '../../inputs/TextInput';

export default class Login extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailError: null,
      password: '',
      passwordError: null,
      buttonMessage: 'Login',
      navToDashboard: false,
    };
  }

  handleTextChange = event => {
    if (this.state[event.target.id] !== undefined) {
      this.setState({ [event.target.id]: event.target.value });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    // temp/fake action
    // navigate to dashboard
    this.setState({
      buttonMessage: 'Oh yeah!',
      navToDashboard: true,
    });
    request
      .post('https://ez-tour.herokuapp.com/users')
      .send({
        full_name: this.state.full_name,
        telephone: this.state.telephone,
        email: this.state.email,
        password: this.state.password,
      })
      .end((err, res) => {
        if (err) {
          console.log(err);
          console.log(res);
          this.setState({ error: res.body.error });
        } else {
          console.log(res);
          this.setState({ navToDashboard: true });
        }
      });
  };

  render() {
    const {
      email,
      emailError,
      password,
      passwordError,
      buttonMessage,
      navToDashboard,
    } = this.state;
    return (
      <div className="login-form">
        {navToDashboard && <Redirect to="/dashboard" />}
        <form>
          <TextInput
            htmlId="email"
            name="email"
            label="email"
            type="text"
            required
            onChange={this.handleTextChange}
            placeholder="Email address"
            value={email}
            // readOnly={true}
            error={emailError}
            // props="readOnly"
            // children
          />
          {/* password button */}
          <TextInput
            htmlId="password"
            name="password"
            label="password"
            type="text"
            required
            onChange={this.handleTextChange}
            placeholder="Password"
            value={password}
            // readOnly={true}
            error={passwordError}
            // props="readOnly"
            // children
          />
          <p>forgot password?</p>
          <button
            type="submit"
            className="login-button"
            onClick={this.handleSubmit}
          >
            {buttonMessage}
          </button>
        </form>
      </div>
    );
  }
}
