/* eslint-disable no-unused-vars */ // CI treats warnings as errors - build fails
import React from 'reactn';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import request from 'superagent';

/* eslint-disable import/no-webpack-loader-syntax*/
import BitcoinIcon from '../../styles/icons/Bitcoin.svg';
import EthereumIcon from '../../styles/icons/Ethereum.svg';
import LitecoinIcon from '../../styles/icons/Litecoin.svg';
import PivxIcon from '../../styles/icons/PIVX.svg';
import IopIcon from '../../styles/icons/IOP.svg';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  panelDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: null,
  };

  componentDidMount() {
    // check global data - fetch if empty
    // keeps from making requests every page nav
    if (this.global.currencyDataFetched === false) {
      this.requestAllCurrencyData();
    }
  }

  async getSingleCurrencyData(currency) {
    request
      .get('https://ez-tour.herokuapp.com/users')
      .auth()
      .end((err, res) => {
        if (err) {
          console.log(err);
          console.log(res);
        } else {
          console.log(res);
          // res = {
          //   sendToAddress,
          //   feeSeting,
          //   minconf,
          //   minPayment,
          //   maxPayment,
          // }
        }
      });
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  requestAllCurrencyData() {
    // map over currencies list, fire getSingleCurrencyData for each
    this.setGlobal({ dataFetched: true });
  }

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        <ExpansionPanel
          expanded={expanded === 'panel1'}
          onChange={this.handleChange('panel1')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {/* Plus icon or switch */}

            {/* <BitcoinIcon /> */}
            <img
              src={BitcoinIcon}
              className="currency-icon"
              alt="currency-icon"
            />

            {/* <Typography className={classes.heading}>
              General settings
            </Typography> */}
            <Typography className={classes.secondaryHeading}>
              Bitcoin
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ panelDetails: classes.panelDetails }}
          >
            <Typography>Payout Address</Typography>
            <Typography className={classes.secondaryHeading}>
              This is the Bitcoin address we send your payments to.
            </Typography>
            <Typography>Sending Fee</Typography>
            <Typography className={classes.secondaryHeading}>
              Bitcoin
            </Typography>
            <Typography>Number of Confirmations</Typography>
            <Typography className={classes.secondaryHeading}>
              Bitcoin
            </Typography>
            <Typography>Min and Max accepted amounts</Typography>
            <Typography className={classes.secondaryHeading}>
              Bitcoin
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'panel2'}
          onChange={this.handleChange('panel2')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {/* Plus icon or switch */}

            {/* <LitecoinIcon /> */}
            <img
              src={LitecoinIcon}
              className="currency-icon"
              alt="currency-icon"
            />

            {/* <Typography className={classes.heading}>Users</Typography> */}
            <Typography className={classes.secondaryHeading}>
              Litecoin
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Donec placerat, lectus sed mattis semper, neque lectus feugiat
              lectus, varius pulvinar diam eros in elit. Pellentesque convallis
              laoreet laoreet.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'panel3'}
          onChange={this.handleChange('panel3')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {/* Plus icon or switch */}

            {/* <PivxIcon /> */}
            <img src={PivxIcon} className="currency-icon" alt="currency-icon" />
            {/* <Typography className={classes.heading}>
              Advanced settings
            </Typography> */}
            <Typography className={classes.secondaryHeading}>Pivx</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'panel4'}
          onChange={this.handleChange('panel4')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {/* Plus icon or switch */}
            <img src={IopIcon} className="currency-icon" alt="currency-icon" />
            <Typography className={classes.secondaryHeading}>IOP</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlledExpansionPanels);
