import React, { Component } from 'react';
import Header from './header/Header';

export default class HomePage extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Header />
        <h1>Body</h1>
      </div>
    );
  }
}
