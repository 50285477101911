/* eslint-disable no-unused-vars */ // CI treats warnings as errors - build fails
import React from 'react';
import PropTypes from 'prop-types';
import request from 'superagent';

import TextInput from '../../inputs/TextInput';

export default function BusinessMUI(props) {
  const [fullName, setFullName] = React.useState('');
  const [fullNameError, setFullNameError] = React.useState('');
  const [ein, setEin] = React.useState('');
  const [businessName, setBusinessName] = React.useState('');
  const [businessIndustry, setBusinessIndustry] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [website, setWebsite] = React.useState('');
  const [websiteError, setWebsiteError] = React.useState('');
  const [requestError, setRequestError] = React.useState('');

  // TODO validate data - set errors

  function handleSubmit(event) {
    event.preventDefault();
    props.setComplex('login'); // testing
    // request
    //   .post('https://ez-tour.herokuapp.com/users')
    //   .send({
    //     fullName: fullName,
    //     dateOfBirth: dateOfBirth,
    //     businessName: businessName,
    //     businessIndustry: businessIndustry,
    //     country: country,
    //     website: website,
    //   })
    //   .end((err, res) => {
    //     if (err) {
    //       console.log(err);
    //       console.log(res);
    //       setRequestError(err);
    //     } else {
    //       console.log(res);
    //       // display message?
    //       // send email?
    //       // redirect to loginPage
    //       props.setComplex('login');
    //     }
    //   });
  }

  return (
    <div className="login-form">
      <form>
        <TextInput
          htmlId="fullName"
          name="fullName"
          label="fullName"
          type="text"
          required
          onChange={setFullName}
          placeholder="Full Name"
          value={fullName}
          // readOnly={true}
          error={fullNameError}
          // props="readOnly"
          // children={<div>Hey!</div>}
        />
        {/* password button */}
        {/* <TextInput
          htmlId="dateOfBirth"
          name="dateOfBirth"
          label="dateOfBirth"
          type="text"
          required
          onChange={setDateOfBirth}
          placeholder="dateOfBirth"
          value={dateOfBirth}
          // readOnly={true}
          error={dateOfBirthError}
          // props="readOnly"
          // children
        /> */}
        <TextInput
          htmlId="businessName"
          name="businessName"
          label="businessName"
          type="text"
          required
          onChange={setBusinessName}
          placeholder="Business Name"
          value={businessName}
          // readOnly={true}
          // error={countryError}
          // props="readOnly"
          // children
        />
        <TextInput
          htmlId="businessIndustry"
          name="businessIndustry"
          label="businessIndustry"
          type="text"
          required
          onChange={setBusinessIndustry}
          placeholder="Business Industry"
          value={businessIndustry}
          // readOnly={true}
          // error={countryError}
          // props="readOnly"
          // children
        />
        <TextInput
          htmlId="country"
          name="country"
          label="country"
          type="text"
          required
          onChange={setCountry}
          placeholder="Country"
          value={country}
          // readOnly={true}
          // error={countryError}
          // props="readOnly"
          // children
        />
        <TextInput
          htmlId="website"
          name="website"
          label="website"
          type="text"
          required
          onChange={setWebsite}
          placeholder="Website"
          value={website}
          // readOnly={true}
          error={websiteError}
          // props="readOnly"
          // children
        />
        <button type="submit" className="login-button" onClick={handleSubmit}>
          Complete Registration
        </button>
      </form>
    </div>
  );
}

BusinessMUI.propTypes = {
  setComplex: PropTypes.func.isRequired,
};
