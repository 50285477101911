/* eslint-disable max-len */
export const bars = {
  d:
    'M 16 132 h 416 c 8.837 0 16 -7.163 16 -16 V 76 c 0 -8.837 -7.163 -16 -16 -16 H 16 C 7.163 60 0 67.163 0 76 v 40 c 0 8.837 7.163 16 16 16 Z m 0 160 h 416 c 8.837 0 16 -7.163 16 -16 v -40 c 0 -8.837 -7.163 -16 -16 -16 H 16 c -8.837 0 -16 7.163 -16 16 v 40 c 0 8.837 7.163 16 16 16 Z m 0 160 h 416 c 8.837 0 16 -7.163 16 -16 v -40 c 0 -8.837 -7.163 -16 -16 -16 H 16 c -8.837 0 -16 7.163 -16 16 v 40 c 0 8.837 7.163 16 16 16 Z',
};
export const X = {
  d:
    'M 323.1 441 l 53.9 -53.9 c 9.4 -9.4 9.4 -24.5 0 -33.9 L 279.8 256 l 97.2 -97.2 c 9.4 -9.4 9.4 -24.5 0 -33.9 L 323.1 71 c -9.4 -9.4 -24.5 -9.4 -33.9 0 L 192 168.2 L 94.8 71 c -9.4 -9.4 -24.5 -9.4 -33.9 0 L 7 124.9 c -9.4 9.4 -9.4 24.5 0 33.9 l 97.2 97.2 L 7 353.2 c -9.4 9.4 -9.4 24.5 0 33.9 L 60.9 441 c 9.4 9.4 24.5 9.4 33.9 0 l 97.2 -97.2 l 97.2 97.2 c 9.3 9.3 24.5 9.3 33.9 0 Z',
};
export const leftArrow = {
  d:
    'M 6.4 8.85 h 13.76 a 1.84 1.84 0 1 1 0 3.68 H 5.78 l 2.48 2.48 c 0.72 0.72 0.72 1.88 0 2.6 c -0.72 0.72 -1.88 0.72 -2.6 0 l -5.12 -5.1 A 1.83 1.83 0 0 1 0.01 11 c -0.06 -0.54 0.12 -1.1 0.53 -1.5 l 5.12 -5.11 a 1.85 1.85 0 0 1 2.6 0 c 0.72 0.72 0.72 1.88 0 2.6 Z',
};
